import { InputAdornment, TextField } from "@mui/material";
import Icon from "react-crypto-icons";

const buttonStyle =
{
  "height": "42px",
  "lineHeight": "42px",
  "background": "linear-gradient(111.85deg, #FB87FF -23.82%, #AE44ED 119.4%)",
  "boxShadow": "0px 10px 20px rgba(219, 134, 255, 0.66)",
  "borderRadius": "44px",
  "fontFamily": "Rubik",
  "fontStyle": "normal",
  "fontWeight": "normal",
  "fontSize": "14px",
  "textAlign": "center",
  "textTransform": "uppercase",
  "color": "#FFFFFF"
}
const BidButton = (props) => {
	return <>
	  <TextField
	    name="BID"
	    id="input-with-icon-textfield"
	    type="number"
	    size="small"
	    variant="standard"
	    label="Bid amount"
	    fullWidth={true}
	    InputProps={{
	      startAdornment: (
		<InputAdornment position="start">
		  <Icon name="algo" size={16} />
		</InputAdornment>
	      ),
	      defaultValue: props.defaultValue,
	      value: props.value
	    }}
	    onChange={props.onChange}
	  />
	  <div role="button"
	    style={{
	      ...buttonStyle,
	      "marginTop": "15px"
	    }}
	    onClick={props.onClick}
	  >
	    Make A Bid
	  </div>
	</>
      }
export default BidButton;