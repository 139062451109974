import { useRef } from 'react'
import {
  useHistory,
  useParams
} from "react-router-dom"
import * as backend from '../build/reverse.main.mjs'
import { useState } from "react";
import Form from 'react-bootstrap/Form'
import { DateTimePicker } from '@mui/lab'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import {
  getAssetImage,
} from '../functions'
import './Sell.css'
import SubmitButton from '../components/buttons/SubmitButton';
import { 
  errorLog, 
  ERROR_REJECT_SIGN_TRANSACTION
} from '../errors';
import Success from '../components/Success'

const axios = require('axios')

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let algoexplorerapi_endpoint
if (providerEnv === "MainNet") {
  algoexplorerapi_endpoint = 'https://algoexplorerapi.io'
} else {
  algoexplorerapi_endpoint = 'https://testnet.algoexplorerapi.io'
}

function BasicSelect(props) {
  const handleChange = props.handleChange
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          name={props.name}
          value={props.value}
          variant={props.variant}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={props.label}
          onChange={handleChange}
          disabled={props.disabled}
        >
          {props.items.map(el =>
            <MenuItem value={el["asset-id"]}>{props.displayItem(el)}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}

const Sell = (props) => {
  var moment = require('moment-timezone');
  document.title = "Sell - NFT Jam"
  let history = useHistory()
  let { appId } = useParams()
  console.log({ appId })
  const sliderRef = useRef(null)
  let sliderTimeout
  const {
    acc,
    stdlib,
    ADDR_PLATFORM2,
    ADDR_DISCOVERY3,
  } = props
  const [sliderProps, setSliderProps] = useState({})
  const [state, setState] = useState({
    success: false,
    loading: false,
    bank: null,
    buy: null,
    seconds: 0,
    step: 0
  })
  const [query, setQuery] = useState({
    ASSETID: 0,
    STARTPRICE: 0,
    FLOORPRICE: 0,
  })
  const handleDeposit = async () => {
    const expect = [
      ERROR_REJECT_SIGN_TRANSACTION
    ]
    const cancelLoading = () => setState({...state, loading: false})
    const ctcAuction = acc.contract(backend, parseInt(appId))
    setState({ ...state, loading: true })
    Promise.all([
      backend.Depositer(ctcAuction, {
        ...stdlib.hasConsoleLogger,
        signal: async () => {
          console.log("SIGNAL")
          let image = await getAssetImage(parseInt(query.ASSETID))
          console.log({ image })
          appId = await ctcAuction.getInfo()
          setState({
            ...state,
            loading: false,
            success: true,
            appId,
            image
          })
        },
      })
    ])
      .catch(e => errorLog(expect, e))
      .finally(cancelLoading)
  }
  const handleCreate = async () => {
    if (query.ASSETID === 0) {
      alert("Must select asset")
      return
    }
    else if (query.STARTPRICE <= 0) {
      alert("Start price must be greater than zero")
      return
    }
    else if (query.FLOORPRICE <= 0) {
      alert("Floor price must be greater than zero")
      return
    }
    else if (query.STARTPRICE <= query.FLOORPRICE) {
      alert("Start price must be greater tha floor price")
      return
    }
    setState({ ...state, loading: true })
    console.log("Launching new auction ...")
    const ctcAuction = acc.contract(backend)
    Promise.all([
      backend.Auctioneer(ctcAuction, {
        ...stdlib.hasConsoleLogger,
        getParams: () => ({
          token: parseInt(query.ASSETID),
          addr: ADDR_DISCOVERY3,
          addr2: ADDR_PLATFORM2,
          creator: query.CREATOR,
          startPrice: stdlib.parseCurrency(query.STARTPRICE), // 0 ALGO
          floorPrice: stdlib.parseCurrency(query.FLOORPRICE), // 0 ALGO
          endConsensusTime: 0,
        }),
        signal: async () => {
          appId = await ctcAuction.getInfo()
          history.push(`/sell/reverse/${appId}`)
          setState({
            ...state,
            loading: false,
            appId
          })
        },
      })
    ])
  }
  const handleChange = ({ target }) => {
    let { name, value } = target
    console.log({ name, value })
    switch (name) {
      case 'ASSETID':
        let CREATOR = acc.assets.filter(el => el['asset-id'] === value)[0].creator
        if (CREATOR === acc.address) {
          setQuery({
            ...query,
            [name]: value,
            CREATOR,
            ROYALTYCENTS: 0,
          })
        } else {
          setQuery({
            ...query,
            [name]: value,
            CREATOR
          })
        }
        break
      case 'STARTBID':
      case 'FLOORPRICE':
      case 'STARTPRICE':
      case 'RESERVEPRICE':
      case 'ROYALTYCENTS':
        if (value.length > 1 && value[0] === '0') {
          value = value.slice(1)
        }
        setQuery({
          ...query,
          [name]: value
        })
        break
      default:
    }
  }
  const handleSellOneMore = () => {
    appId = null
    history.push(`/sell`)
    setState({
      success: false
    })
    setQuery({})
  }
  const handleOk = () => {
    history.push(`/reverse/${appId}`)
  }
  const handleCancel = () => {
    history.push(`/`)
  }
  const serviceNameTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "900",
    "fontSize": "32px",
    "lineHeight": "38px",
    "textAlign": "center",
    "letterSpacing": "0.1em",
    "textTransform": "uppercase"
  }
  const cardStyle =
  {
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "50px 56px",
    "width": "565px",
    //"height": "645px",
    "paddingBottom": "20px"
  }
  const compactCardStyle =
  {
    "position": "fixed",
    "top": "15%",
    "left": "5vw",
    "width": "90vw",
    "background": "#FFFFFF",
    "borderRadius": "36px",
    "padding": "20px 30px",
    "marginTop": "0vh",
    //"maxWidth": "90vw",
    //"maxHeight": "70vh",
    //"width": "465px",
    //"height": "588px"
  }
  const labelTypographyStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "300",
    "fontSize": "12px",
    "lineHeight": "14px",
    "color": "#C558F3"
  }
  const labelStyle =
  {
    "height": "14px",
    "marginBottom": "4px"
  }
  const cancelStyle =
  {
    "fontFamily": "Rubik",
    "fontStyle": "normal",
    "fontWeight": "normal",
    "fontSize": "14px",
    "lineHeight": "17px",
    "textAlign": "center",
    "textTransform": "uppercase",
    "color": "#FF4747",
    "marginTop": "32px"
  }
  return <div id="sell" style={{ paddingBottom: "100px" }}>
    {!state.success ?
      appId
        ? <>
          <div className="" style={{ ...cardStyle }} >
            <div style={{ ...serviceNameTypographyStyle, ...{ "fontSize": "22px" } }}>Sell Your NFT</div>
            <Stack className="mt-3" spacing={4}>
              {[
                {
                  name: "ASSETID",
                  label: "Asset Id",
                },
                {
                  name: "STARTPRICE",
                  label: "Start price",
                },
                {
                  name: "FLOORPRICE",
                  label: "Floor price",
                },
              ].map(el =>
                <Box style={{ "textAlign": "left" }}>
                  <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{el.label}</label>
                  <TextField
                    name={el.name}
                    id="standard-number"
                    fullWidth={true}
                    type="text"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    value={query[el.name]}
                    onChange={handleChange}
                    disabled
                  />
                </Box>)}
              <SubmitButton onClick={handleDeposit} label="Step 2: Configure Auction" loading={state.loading} />
              <div role="button" style={cancelStyle} onClick={handleCancel}>Cancel</div>
            </Stack>
          </div>
        </>
        : <>
          <div className="d-xs-block d-sm-none" style={{ ...compactCardStyle }} >
            <div style={{ ...serviceNameTypographyStyle, ...{ "fontSize": "22px" } }}>Sell Your NFT</div>
            <Stack className="mt-3" spacing={4}>
              <Box style={{ "textAlign": "left" }}>
                {!appId
                  ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{"Asset id"}</label>
                  : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{"Asset id"}</label>}
                <BasicSelect
                  name="ASSETID"
                  value={query.ASSETID}
                  handleChange={handleChange}
                  items={acc?.assets?.filter(el => el.amount > 0) || []}
                  displayItem={(el) => ((assets) => assets.length > 0 ? ((asset) => `${asset.index} : ${asset?.params?.name} (${asset?.params["unit-name"]}) : ${el.amount}`)(assets[0]) : "")(((assetId) => acc.assetInfo.filter(el => el.index === assetId))(el["asset-id"]))}
                  variant="standard" />
              </Box>
              {[
                {
                  name: "STARTBID",
                  label: "Start bid"
                },
                {
                  name: "STARTPRICE",
                  label: "Start price"
                },
                {
                  name: "RESERVEPRICE",
                  label: "Reserve price"
                },
                {
                  name: "ROYALTYCENTS",
                  label: "Royalty (%)"
                }
              ].map(el =>
                <Box style={{ "textAlign": "left" }}>
                  {!appId
                    ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{el.label}</label>
                    : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{el.label}</label>}
                  <TextField
                    name={el.name}
                    id="standard-number"
                    fullWidth={true}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="standard"
                    value={query[el.name]}
                    onChange={handleChange}
                  />
                </Box>)}
              <Box style={{ "textAlign": "left" }}>
                {!appId
                  ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{"Deadline date"}</label>
                  : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{"Deadline date"}</label>}
                <DateTimePicker
                  name="DEADLINEDATE"
                  value={query.DEADLINEDATE}
                  onChange={(date) => setQuery({ ...query, DEADLINEDATE: date })}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      fullWidth={true}
                      variant="standard" />}
                />
              </Box>
              <SubmitButton onClick={handleCreate} label={appId ? "Step 2: Configure Auction" : "Step 1: Create Auction"} loading={state.loading} />
              <div role="button" style={cancelStyle} onClick={handleCancel}>Cancel</div>
            </Stack>
          </div>
          <div id="sell" className="d-none d-sm-block" style={cardStyle}>
            <div style={serviceNameTypographyStyle}>Sell Your NFT</div>
            <Stack className="mt-4 pt-1" spacing={5}>
              {!appId && <>
                <Box style={{ "textAlign": "left" }}>
                  {!appId
                    ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{"Asset id"}</label>
                    : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{"Asset id"}</label>}
                  <BasicSelect
                    name="ASSETID"
                    value={query.ASSETID}
                    handleChange={handleChange}
                    items={acc?.assets?.filter(el => el.amount > 0) || []}
                    displayItem={(el) => ((assets) => assets.length > 0 ? ((asset) => `${asset.index} : ${asset?.params?.name} (${asset?.params["unit-name"]}) : ${el.amount}`)(assets[0]) : "")(((assetId) => acc.assetInfo.filter(el => el.index === assetId))(el["asset-id"]))}
                    variant="standard" />
                </Box>
                {[
                  {
                    name: "STARTBID",
                    label: "Start bid",
                    visible: false,
                  },
                  {
                    name: "STARTPRICE",
                    label: "Start price",
                    visible: true,
                  },
                  {
                    name: "FLOORPRICE",
                    label: "Floor price",
                    visible: true,
                  },
                  {
                    name: "RESERVEPRICE",
                    label: "Reserve price",
                    visible: false,
                  },
                  {
                    name: "ROYALTYCENTS",
                    label: "Royalty (%)",
                    visible: false,
                  }
                ].map(el => el.visible &&
                  <Box style={{ "textAlign": "left" }}>
                    {!appId
                      ? <label style={{ ...labelStyle, ...labelTypographyStyle, "color": "grey" }}>{el.label}</label>
                      : <label style={{ ...labelStyle, ...labelTypographyStyle }}>{el.label}</label>}
                    <TextField
                      name={el.name}
                      id="standard-number"
                      fullWidth={true}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                      value={query[el.name]}
                      onChange={handleChange}
                    />
                  </Box>)}
              </>}
              <SubmitButton onClick={handleCreate} label={appId ? "Step 2: Deposit NFT" : "Step 1: Create Auction"} loading={state.loading} />
              <div role="button" style={cancelStyle} onClick={handleCancel}>Cancel</div>
            </Stack>
            <div className="jam default" style={{ margin: '24px auto' }}>
              {false && <div style={{ 'textAlign': 'left' }}>
                <label style={{ ...labelStyle, ...labelTypographyStyle }}>{"Asset id"}</label>
                {acc?.assets && <Form.Select name="ASSETID" size="lg" onChange={handleChange}>
                  <option></option>
                  {acc.assets.filter(el => el.amount > 0).map(el =>
                    <option value={el['asset-id']}>
                      {((assets) => assets.length > 0 ? ((asset) => `${asset.index} : ${asset?.params?.name} (${asset?.params["unit-name"]}) : ${el.amount}`)(assets[0]) : "")(((assetId) => acc.assetInfo.filter(el => el.index === assetId))(el["asset-id"]))}
                    </option>)}
                </Form.Select>}
              </div>}
            </div>
          </div>
        </> : <Success appId={state.appId} onSellMore={handleSellOneMore} onOk={handleOk} {...query} />
    }
  </div >
}

export default Sell;